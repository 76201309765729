import { _js, ___p } from '@ifixit/localize';

const SECOND = 1000,
   MINUTE = 60 * SECOND,
   HOUR = 60 * MINUTE,
   DAY = 24 * HOUR,
   WEEK = 7 * DAY,
   YEAR = DAY * 365,
   MONTH = YEAR / 12;

onDomReady(() => {
   // Refresh interval in milliseconds.
   let refreshInterval = MINUTE;
   (function update() {
      document.querySelectorAll('.js-realtime-date').forEach(el => {
         try {
            const dateHtml = relativeDate(new Date(el.title));

            if (dateHtml) {
               el.innerHTML = dateHtml;
            }
         } catch {
            // Do nothing. Since this is an HTML5 time element older browsers
            // may have trouble with this.
         }
      });
      setTimeout(update, refreshInterval);
   })();
});

let absoluteDate = function (dateFormat, time) {
   const locale = App.locale.replace('_', '-');

   switch (dateFormat) {
      case 'time': {
         return time.toLocaleTimeString(locale);
      }
      case 'date': {
         return time.toLocaleDateString(locale);
      }
      case 'year': {
         return time.toLocaleString(locale, { year: 'numeric' });
      }
      case 'full':
      default: {
         return time.toLocaleString(locale, {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
         });
      }
   }
};

document.querySelectorAll('.js-absolute-date').forEach(el => {
   try {
      el.innerHTML = absoluteDate(el.dataset.format, new Date(el.title));
   } catch {
      // Since this is an HTML5 time element older browsers may have trouble
      // with this. Set the html to the unlocalized date string.
      el.innerHTML = el.title;
   }
});

function relativeDate(time, useHours = false, useDays = false) {
   let delta = Date.now() - time.getTime();
   let isFutureDate = delta < 0;

   delta = Math.abs(delta);

   if (delta < 15 * SECOND) {
      return _js('just now');
   }
   if (delta < MINUTE) {
      const modifiedDelta = Math.floor(delta / SECOND);
      return isFutureDate
         ? ___p(modifiedDelta, '%1 second from now', '%1 seconds from now', modifiedDelta)
         : ___p(modifiedDelta, '%1 second ago', '%1 seconds ago', modifiedDelta);
   }
   if (delta < HOUR) {
      const modifiedDelta = Math.floor(delta / MINUTE);
      return isFutureDate
         ? ___p(modifiedDelta, '%1 minute from now', '%1 minutes from now', modifiedDelta)
         : ___p(modifiedDelta, '%1 minute ago', '%1 minutes ago', modifiedDelta);
   }
   if (useHours && delta < DAY) {
      const modifiedDelta = Math.floor(delta / HOUR);
      return isFutureDate
         ? ___p(modifiedDelta, '%1 hour from now', '%1 hours from now', modifiedDelta)
         : ___p(modifiedDelta, '%1 hour ago', '%1 hours ago', modifiedDelta);
   }
   if (useDays && delta < MONTH) {
      const modifiedDelta = Math.floor(delta / DAY);
      return isFutureDate
         ? ___p(modifiedDelta, '%1 day from now', '%1 days from now', modifiedDelta)
         : ___p(modifiedDelta, '%1 day ago', '%1 days ago', modifiedDelta);
   }

   return null;
}

export { relativeDate, MINUTE };
